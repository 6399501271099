import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ProgressBar from "../components/ProgressBar";
import useServices from "../hooks/useServices";
import CreateServiceCard from "../containers/createServiceCard";
// import { EyeIconSvg } from "../svgs/eyeIconSvg";
// import { EditIconSvg } from "../svgs/editIconSvg";
import styles from "../styles/createService.module.css";

const CreateService = () => {
  const navigate = useNavigate();

  // REDIRECT IF NO TOKEN
  useEffect(() => {
    let token = localStorage.getItem("auth_token");
    if (!token) {
      navigate("/register");
    }
  }, []);

  const [services] = useServices();

  const [view, setView] = useState(0);

  const [isBelow992px, setIsBelow992px] = useState(window.innerWidth < 992);

  useEffect(() => {
    const handleResize = () => {
      setIsBelow992px(window.innerWidth < 992);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className={styles.container}>
      <ProgressBar />
      {/* <div className={styles.toggleView}>
        <div className={styles.box} onClick={() => setView(1)}>
          <EyeIconSvg fill={view === 1 ? "#DE1468" : "#000000"} />
        </div>
        <div className={styles.box} onClick={() => setView(0)}>
          <EditIconSvg fill={view === 0 ? "#DE1468" : "#000000"} />
        </div>
      </div> */}
      <div className={styles.wrapper}>
        <main
          className={styles.main}
          style={{
            display: isBelow992px && view === 1 ? "none" : "flex",
          }}
        >
          <CreateServiceCard />
          <span
            style={{
              marginTop: "20px",
              cursor: "pointer",
            }}
            onClick={() => navigate("/services")}
          >
            חזרה לעמוד השירותים
          </span>
        </main>

        {/* PREVIEW */}
        {/* <div className={styles.verticalLine} />

        <div
          className={styles.preview}
          style={{
            display: isBelow992px && view === 0 ? "none" : "flex",
          }}
        >
          <h2 className={styles.previewMainHeading}>תצוגה מקדימה של העסק</h2>
          <h2 className={styles.servicesMainHeading}>בחירת שירות</h2>
          <p className={styles.servicesMainText}>ניתן לבחור שירות אחד בלבד</p>
          <div className={styles.services}>
            {services?.map((i) => (
              <div className={`${styles.card}`} key={i._id}>
                <div className={styles.cardImg}>
                  <h3>{i.name.slice(0, 1)}</h3>
                </div>

                <div className={styles.cardContent}>
                  <h5 className={styles.name}>{i.name}</h5>
                  <p className={styles.desc}>{i.description}</p>

                  <div className={styles.row}>
                    <div className={styles.block}>
                      <img src="/assets/clock-2.svg" />
                      <p>{i.time} דקות</p>
                    </div>
                    {i.price > 0 && (
                      <div
                        className={styles.block}
                        style={{
                          maxWidth: !i.isExactPrice ? "120px" : "60px",
                          minWidth: !i.isExactPrice ? "100px" : "60px",
                        }}
                      >
                        <p>
                          {i.isExactPrice ? " " : "החל מ-"}₪{i.price}
                        </p>
                      </div>
                    )}
                  </div>
                </div>

                <button className={styles.btn}>
                  <svg
                    width="26"
                    height="24"
                    viewBox="0 0 26 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0.439341 10.9393C-0.146446 11.5251 -0.146446 12.4749 0.439341 13.0607L9.98528 22.6066C10.5711 23.1924 11.5208 23.1924 12.1066 22.6066C12.6924 22.0208 12.6924 21.0711 12.1066 20.4853L3.62132 12L12.1066 3.51472C12.6924 2.92893 12.6924 1.97919 12.1066 1.3934C11.5208 0.807611 10.5711 0.807611 9.98528 1.3934L0.439341 10.9393ZM25.5 10.5L1.5 10.5V13.5L25.5 13.5V10.5Z"
                      fill="#140635"
                    />
                  </svg>
                </button>
              </div>
            ))}
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default CreateService;
