export const ProfileUpdateIconSvg = ({ fill }) => {
  return (
    <svg
      width="41"
      height="40"
      viewBox="0 0 41 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1358_12418)">
        <path
          d="M36.8499 6.56646C34.2833 12.9665 27.85 21.6665 22.4666 25.9831L19.1833 28.6165C18.7666 28.9165 18.35 29.1831 17.8833 29.3665C17.8833 29.0665 17.8666 28.7331 17.8166 28.4165C17.6333 27.0165 17 25.7165 15.8833 24.5998C14.75 23.4665 13.3666 22.7998 11.95 22.6165C11.6166 22.5998 11.2833 22.5665 10.95 22.5998C11.1333 22.0831 11.4166 21.5998 11.7666 21.1998L14.3666 17.9165C18.6666 12.5331 27.3999 6.06646 33.7833 3.51646C34.7666 3.1498 35.7166 3.41646 36.3166 4.03313C36.9499 4.6498 37.2499 5.5998 36.8499 6.56646Z"
          stroke={fill}
          strokeWidth="1.6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M17.8835 29.3663C17.8835 31.1997 17.1835 32.9497 15.8668 34.283C14.8501 35.2997 13.4668 35.9997 11.8168 36.2163L7.7168 36.6663C5.48346 36.9163 3.5668 35.0163 3.83346 32.7497L4.28346 28.6497C4.68346 24.9997 7.73346 22.6663 10.9668 22.5997C11.3001 22.583 11.6501 22.5997 11.9668 22.6163C13.3835 22.7997 14.7668 23.4497 15.9001 24.5997C17.0168 25.7163 17.6501 27.0163 17.8335 28.4163C17.8501 28.733 17.8835 29.0497 17.8835 29.3663Z"
          stroke={fill}
          strokeWidth="1.6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M24.2334 24.1177C24.2334 19.7677 20.7001 16.2344 16.3501 16.2344"
          stroke={fill}
          strokeWidth="1.6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M34.0333 21.2178L35.2666 22.4344C37.75 24.9178 37.75 27.3678 35.2666 29.8511L30.3333 34.7844C27.8833 37.2344 25.4 37.2344 22.95 34.7844"
          stroke={fill}
          strokeWidth="1.6"
          strokeLinecap="round"
        />
        <path
          d="M5.68345 17.5156C3.23345 15.0323 3.23345 12.5823 5.68345 10.099L10.6168 5.16562C13.0668 2.71562 15.5501 2.71562 18.0001 5.16562L19.2334 6.39896"
          stroke={fill}
          strokeWidth="1.6"
          strokeLinecap="round"
        />
        <path
          d="M19.2502 6.41602L13.0835 12.5827"
          stroke={fill}
          strokeWidth="1.6"
          strokeLinecap="round"
        />
        <path
          d="M34.0332 21.2178L29.0999 26.1344"
          stroke={fill}
          strokeWidth="1.6"
          strokeLinecap="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1358_12418">
          <rect
            width="40"
            height="40"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
