import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { BASE_URL } from "../config";
import Spinner from "../components/Spinner/Spinner";
import styles from "../styles/updateProfileCard.module.css";

let colors = [
  { color: "#007BFF" },
  { color: "#E273AA" },
  { color: "#111827" },
  { color: "#28A745" },
  { color: "#C82333" },
  { color: "#FD7E14" },
  { color: "#D00254" },
  { color: "#9932CC" },
  { color: "#884A39" },
];

const UpdateProfileCard = ({ formdata, setFormdata }) => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [resStatus, setResStatus] = useState(null);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const defaultDescription =
    "ברוכים הבאים! אנו כאן כדי לספק לכם את השירות/המוצר הטוב ביותר עבורך. הצוות שלנו מחויב לאיכות ולשביעות רצון הלקוח. אנו מזמינים אתכם להכיר אותנו ולהרגיש חופשי ליצור קשר עם כל שאלה או בקשה.";

  let businessId = localStorage.getItem("business_id");
  const authToken = localStorage.getItem("auth_token");

  const [galleryImages, setGalleryImages] = useState([]);

  // FETCH PROFILE DETAILS
  useEffect(() => {
    setResStatus(true);
    setSuccessMessage("");
    setErrorMessage("");
    const fetchData = async () => {
      setLoading(true);
      try {
        const config = {
          headers: {
            Authorization: authToken,
          },
        };
        const res = await axios.get(
          `${BASE_URL}/business/profile_data`,
          config
        );

        setFormdata({
          businessName: res.data.data.business_name
            ? res.data.data.business_name
            : "",
          description: res.data.data.description
            ? res.data.data.description
            : "",
          // businessImage:
          //   res.data.data.business_image &&
          //   (res.data.data.business_image.includes("http") ||
          //   res.data.data.business_image.includes("data:image/")
          //     ? res.data.data.business_image
          //     : `${BASE_URL}/v1/uploads/${res.data.data.business_image}`),
          businessImage: res.data.data.business_image
            ? `${BASE_URL}/uploads/${res.data.data.business_image}`
            : null,

          profileImage: res.data.data.profile
            ? `${BASE_URL}/uploads/${res.data.data.profile}`
            : null,

          phone: res.data.data.phone ? res.data.data.phone : "",
          whatsApp: res.data.data.whatsApp_phone
            ? res.data.data.whatsApp_phone
            : "",
          instagramId: res.data.data.instagramId
            ? res.data.data.instagramId
            : "",
          facebook: res.data.data.facebook ? res.data.data.facebook : "",
          tiktok: res.data.data.tiktok ? res.data.data.tiktok : "",
          website: res.data.data.website ? res.data.data.website : "",
          color: res.data.data.color,
          images: res.data.data.multiImage.map((i) => {
            return `${BASE_URL}/uploads/${i}`;
          }),
          previewImages: res.data.data.multiImage.map((i) => {
            return `${BASE_URL}/uploads/${i}`;
          }),
        });
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    };

    fetchData();
  }, [resStatus]);

  // HANDLE INPUT CHANGE
  const handleChange = (e) => {
    const { name, value } = e.target;

    let updatedValue = value;
    // Check if the name is 'description' and the value is empty.
    if (name === "description" && value.trim() === "") {
      updatedValue = defaultDescription;
    }
    if (name === "instagramId") {
      const instaUrlPattern =
        /((http|https):\/\/)?(www.)?instagram.com\/([a-zA-Z0-9_]+)/;
      const match = value.match(instaUrlPattern);

      if (match && match[4]) {
        updatedValue = match[4]; // Extract the username
      }
    }

    setFormdata((prev) => {
      return {
        ...prev,
        [name]: updatedValue,
      };
    });
  };

  // HANDLE PROFILE IMAGE CHANGE
  const handleProfileImageChange = async (e) => {
    var file = e.target.files[0];
    setFormdata((prev) => {
      return {
        ...prev,
        profileImage: URL.createObjectURL(file),
      };
    });
    try {
      const formData = new FormData();
      formData.append("profile", file);

      const response = await axios.put(
        `${BASE_URL}/business/update_business_cover_profile`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: authToken,
          },
        }
      );
    } catch (error) {
      console.error("API Error:", error);
    }
  };

  // HANDLE COVER IMAGE CHANGE
  const handleBusinessImageChange = async (e) => {
    var file = e.target.files[0];
    setFormdata((prev) => {
      return {
        ...prev,
        businessImage: URL.createObjectURL(file),
      };
    });
    try {
      const formData = new FormData();
      formData.append("cover", file);

      const response = await axios.put(
        `${BASE_URL}/business/update_business_cover_profile`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: authToken,
          },
        }
      );
    } catch (error) {
      console.error("API Error:", error);
    }
  };

  // HANDLE GALLERY
  const handleGallery = async (e) => {
    var file = e.target.files[0];
    let convertedFiles = Array.from(e.target.files).map((x) =>
      URL.createObjectURL(x)
    );
    console.log(convertedFiles, "convertedFiles");
    setFormdata((prev) => {
      return {
        ...prev,
        // images: [...prev.images, file],
        previewImages: [...prev.previewImages, ...convertedFiles],
      };
    });
    setGalleryImages([...e.target.files]);
  };

  // REMOVE IMAGE FROM GALLERY
  const removeImage = (img) => {
    setFormdata((prev) => {
      return {
        ...prev,
        images: prev.images.filter((j, idx) => idx !== img),
        previewImages: prev.previewImages.filter((j, idx) => idx !== img),
      };
    });
  };

  // UPDATE PROFILE
  const handleSubmit = async () => {
    setLoading2(true);
    setSuccessMessage("");
    setErrorMessage("");
    try {
      const config = {
        headers: {
          Authorization: authToken,
        },
      };

      let payload = {
        id: businessId,
      };

      if (formdata.color) {
        payload.color = formdata.color;
      }
      if (formdata.tiktok) {
        payload.tiktok = formdata.tiktok;
      }
      if (formdata.facebook) {
        payload.facebook = formdata.facebook;
      }
      if (formdata.instagramId) {
        payload.instagramId = formdata.instagramId;
      }
      if (formdata.whatsApp) {
        payload.whatsApp_phone = formdata.whatsApp;
      }
      if (formdata.description) {
        payload.description = formdata.description;
      }
      if (formdata.website) {
        payload.website = formdata.website;
      }
      if (formdata.businessName) {
        payload.business_name = formdata.businessName;
      }
      const res = await axios.put(
        `${BASE_URL}/business/update_profile_info`,
        payload,
        config
      );
      if (res.status === 200) {
        handleGalleryUpload();
      }
    } catch (error) {
      console.log(error);
      setErrorMessage(error.response.data.message);
      setLoading2(false);
    }
  };

  let formData = new FormData();

  const fetchAndConvertImages = (images) => {
    return Promise.all(
      images.map((image, index) => {
        return fetch(image)
          .then((response) => response.blob())
          .then((blob) => {
            const file = new File([blob], `existing_image_${index}.jpeg`, {
              type: "image/jpeg",
            });
            formData.append("multipleImage", file);
            console.log(file);
          })
          .catch((error) => {
            console.error("Error converting URL to file:", error);
          });
      })
    );
  };

  const handleGalleryUpload = async () => {
    if (galleryImages.length > 0) {
      galleryImages.forEach((x) => {
        formData.append("multipleImage", x);
      });
    }

    fetchAndConvertImages(formdata.images).then(() => {
      axios
        .put(`${BASE_URL}/business/update_business_cover_profile`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: authToken,
          },
        })
        .then((response) => {
          console.log("Image uploaded:", response.data);
          setResStatus(true);
          setSuccessMessage("Updated Successfully");
          setTimeout(() => {
            navigate("/register/success");
            setLoading2(false);
          }, 1200);
        })
        .catch((error) => {
          console.error("Error uploading image:", error);
        });
    });
  };

  if (loading) {
    return null;
  }

  return (
    <div className={styles.container}>
      <div className={styles.profile}>
        {/* COVER IMAGE */}
        <div className={styles.cover}>
          <img
            src={
              formdata.businessImage
                ? formdata.businessImage
                : "/assets/cover_placeholder.jpeg"
            }
            alt="cover"
            className={styles.img}
          />

          <div className={styles.icon}>
            <input
              type="file"
              accept="image/png, image/jpeg"
              onChange={handleBusinessImageChange}
            />
            <img src="/assets/gallery-icon-1.svg" alt="gallery" />
          </div>
        </div>

        {/* PROFILE IMAGE */}
        <div className={styles.main}>
          <img
            src={
              formdata.profileImage
                ? formdata.profileImage
                : "/assets/cover_placeholder.jpeg"
            }
            alt="user"
            className={styles.img}
          />
          <div className={styles.icon}>
            <input
              type="file"
              accept="image/png, image/jpeg"
              onChange={handleProfileImageChange}
            />
            <img src="/assets/gallery-icon-1.svg" alt="gallery" />
          </div>
        </div>
      </div>

      <div className={styles.body}>
        {/* DESCRIPTION */}
        <div className={styles.desc}>
          <h4>תיאור קצר</h4>
          <textarea
            name="description"
            value={formdata.description || defaultDescription}
            onChange={handleChange}
            placeholder="כאן ניתן לרשום את ההודעה שלך"
          ></textarea>
          <div className={styles.row}>
            <p>ניתן לרשום עד 200 תווים</p>
            <p>
              תווים שנותרו:{" "}
              {200 - (formdata.description.length || defaultDescription.length)}
            </p>
          </div>
        </div>

        {/* FIELDS */}
        <div className={styles.form}>
          <div className={styles.field}>
            <label>
              מספר whatsApp <span>(אופציונלי)</span>
            </label>
            <input
              type="text"
              name="whatsApp"
              value={formdata.whatsApp}
              onChange={handleChange}
              placeholder="הזינו את המספר"
            />
          </div>

          <div className={styles.field}>
            <label>
              קישור אינסטגרם <span>(אופציונלי)</span>
            </label>
            <input
              type="text"
              name="instagramId"
              value={formdata.instagramId}
              onChange={handleChange}
              placeholder="יש להזין את שם המשתמש או הקישור"
            />
          </div>

          <div className={styles.field}>
            <label>
              קישור פייסבוק <span>(אופציונלי)</span>
            </label>
            <input
              type="text"
              name="facebook"
              value={formdata.facebook}
              onChange={handleChange}
              placeholder="קישור לפייסבוק"
            />
          </div>

          <div className={styles.field}>
            <label>
              קישור טיקטוק <span>(אופציונלי)</span>
            </label>
            <input
              type="text"
              name="tiktok"
              value={formdata.tiktok}
              onChange={handleChange}
              placeholder="קישור לטיקטוק"
            />
          </div>

          <div className={styles.field}>
            <label>
              קישור אתר אינטרנט <span>(אופציונלי)</span>
            </label>
            <input
              type="text"
              name="website"
              value={formdata.website}
              onChange={handleChange}
              placeholder="כאן מזינים את האתר שלכם"
            />
          </div>
        </div>

        {/* COLOR */}
        <div className={styles.color}>
          <div className={styles.title}>
            <h4>בחירת צבע העסק</h4>
            <span>חדש</span>
          </div>
          <p>בחרו את צבע האתר והאפליקציה שלכם</p>

          <div className={styles.colors}>
            {colors.map((i) => (
              <div
                key={i.color}
                className={`${styles.circle} ${
                  formdata.color &&
                  formdata.color !== "null" &&
                  formdata.color.toLowerCase() === i.color.toLowerCase()
                    ? styles.active
                    : ""
                }`}
                style={{ backgroundColor: i.color }}
                onClick={() => {
                  setFormdata((prev) => {
                    return {
                      ...prev,
                      color: i.color,
                    };
                  });
                }}
              />
            ))}
          </div>
        </div>

        {/* GALLERY */}
        <div className={styles.gallery}>
          <div className={styles.title}>
            <h4>גלריית תמונות</h4>
          </div>
          <p className={styles.text}>
            בחרו תמונות של העבודות הכי טובות שלכם, התמונות יוצגו ללקוחות באתר
            שלכם
          </p>

          <div className={styles.upload}>
            <div className={styles.uploadBtn}>
              <img src="/assets/gallery-icon-1.svg" alt="gallery-icon" />
              <p>בחירת תמונה</p>
              <input
                type="file"
                onChange={handleGallery}
                multiple
                accept="image/png, image/jpeg"
                disabled={formdata.previewImages.length === 8}
                style={{
                  cursor:
                    formdata.images.length === 8 ? "not-allowed" : "pointer",
                }}
              />
            </div>

            <p className={styles.text}>ניתן לעלות עד 8 תמונות</p>
          </div>

          {formdata.previewImages.length > 0 && (
            <div className={styles.images}>
              {formdata.previewImages.map((i, idx) => (
                <div className={styles.image} key={i}>
                  <span
                    className={styles.minus}
                    onClick={() => removeImage(idx)}
                  >
                    -
                  </span>
                  <img src={i} alt="placeholder" className={styles.img} />
                </div>
              ))}
            </div>
          )}
        </div>

        <button className={styles.submit} onClick={handleSubmit}>
          {loading2 ? (
            <>
              <Spinner /> <span>מעלה תמונות...</span>
            </>
          ) : (
            "הקמת העסק"
          )}
        </button>

        {successMessage && (
          <div className={styles.success}>העסק עודכן בהצלחה!</div>
        )}
        {errorMessage && <div className={styles.error}>{errorMessage}</div>}
      </div>
    </div>
  );
};

export default UpdateProfileCard;
