import axios from "axios";
import React, { useState } from "react";
import ReactSwitch from "react-switch";
import { BASE_URL } from "../config";
import { useNavigate } from "react-router-dom";
import { BellIcon1Svg } from "../svgs/bellIcon1Svg";
import { GraduationHatIconSvg } from "../svgs/graduationHatIconSvg";
import Spinner from "../components/Spinner/Spinner";
import styles from "../styles/createServiceCard.module.css";

const CreateServiceCard = () => {
  const navigate = useNavigate();

  const businessID = localStorage.getItem("business_id");
  const authToken = localStorage.getItem("auth_token");

  const [formdata, setFormdata] = useState({
    id: "",
    type: "REGULAR",
    name: "",
    description: "",
    time: "",
    price: "",
    maximumQueue: 1,
    employeeId: "",
    isExactPrice: true,
    restingTime: "",
    // offsetTime: "",
    offsetNum: "",
    location: "",
    forSubscriptionOnly: false,
  });

  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    setFormdata((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
      };
    });
  };

  // CREATE SERVICE
  const create = async (e) => {
    e.preventDefault();

    let payload = {
      employeeId: businessID,
      type: formdata.type,
      name: formdata.name,
      description: formdata.description,
      time: formdata.time,
      price: formdata.price,
      maximumQueue: formdata.maximumQueue,
      isExactPrice: formdata.isExactPrice,
      restingTime: formdata.restingTime,
      // timeOffset: formdata.offsetNum + formdata.offsetTime,
      location: formdata.location,
      forSubscriptionOnly: formdata.forSubscriptionOnly,
    };

    const config = {
      headers: {
        Authorization: authToken,
      },
    };

    setLoading(true);
    try {
      const res = await axios.post(
        `${BASE_URL}/services/create`,
        payload,
        config
      );

      if (res.status === 201) {
        navigate("/services");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <h2>צור שירות חדש עבור הלקוחות שלך</h2>
        <p className={styles.mainText}>
          כאן מוסיפים את השירותים/שיעורים שאתם נותנים, למשל: גבות, תספורת + זקן,
          לק ג'ל, יוגה, אימון זוגי וכו׳..
        </p>

        <div className={styles.types}>
          <h4>סוג השירות</h4>

          {/* TYPE */}
          <div className={styles.row}>
            {/* REGULAR */}
            <div
              className={`${styles.type} ${
                formdata.type === "REGULAR" ? styles.active : ""
              }`}
              onClick={() => {
                setFormdata((prev) => {
                  return {
                    ...prev,
                    type: "REGULAR",
                  };
                });
              }}
            >
              <BellIcon1Svg
                fill={formdata.type === "REGULAR" ? "#DE1468" : "#CFCFCF"}
              />
              <h5>שירות רגיל</h5>
              <button>נבחר</button>
            </div>
            {/* CLASS */}
            <div
              className={`${styles.type} ${
                formdata.type === "CLASS" ? styles.active : ""
              }`}
              onClick={() => {
                setFormdata((prev) => {
                  return {
                    ...prev,
                    type: "CLASS",
                  };
                });
              }}
            >
              <GraduationHatIconSvg
                fill={formdata.type === "CLASS" ? "#DE1468" : "#CFCFCF"}
              />
              <h5>שיעור</h5>
              <button>בחירה</button>
            </div>
          </div>
        </div>

        <div className={styles.form}>
          {/* NAME */}
          <div className={styles.field}>
            <label>
              שם השירות <span>*</span>
            </label>
            <input
              type="text"
              name="name"
              placeholder="רשום את שם השירות לבחירתך"
              value={formdata.name}
              onChange={handleChange}
            />
          </div>
          {/* TIME */}
          <div className={styles.field}>
            <label>
              זמן השירות <span>*</span>
            </label>
            <input
              type="number"
              name="time"
              placeholder="רשום את זמן השירות בדקות (לדוגמא: 00:35)"
              value={formdata.time}
              onChange={handleChange}
            />
          </div>
          {/* PRICE */}
          <div className={styles.field}>
            <label>
              מחיר השירות <span>(אופציונלי)</span>
            </label>
            <div className={styles.fixedPrice}>
              <select
                name="isExactPrice"
                value={formdata.isExactPrice}
                onChange={handleChange}
              >
                <option value={true}>מחיר קבוע</option>
                <option value={false}>החל מ</option>
              </select>
              <input
                type="number"
                name="price"
                placeholder="סכום"
                value={formdata.price}
                onChange={handleChange}
              />
            </div>
          </div>
          {/* OFFSET TIME */}
          {/* <div className={styles.field}>
            <label>זמן קביעת תור עתידי</label>
            <div className={styles.fixedPrice}>
              <select
                name="offsetTime"
                value={formdata.offsetTime}
                onChange={handleChange}
              >
                <option value="h">שעות</option>
                <option value="d">ימים</option>
                <option value="w">שבועות</option>
                <option value="m">חודשים</option>
                <option value="y">שנים</option>
              </select>
              <input
                type="number"
                name="offsetNum"
                placeholder="1"
                value={formdata.offsetNum}
                onChange={handleChange}
              />
            </div>
          </div> */}
          {/* DESCRIPTION */}
          <div className={styles.field}>
            <label>
              תיאור השרות <span>(אופציונלי)</span>
            </label>
            <input
              type="text"
              name="description"
              value={formdata.description}
              onChange={handleChange}
              placeholder="לדוגמא: ניקוי עמוק, נא להגיע עם חלוק"
            />
          </div>
          {/* LOCATION */}
          <div className={styles.field}>
            <label>
              מיקום <span>(אופציונלי)</span>
            </label>
            <input
              type="text"
              name="location"
              value={formdata.location}
              onChange={handleChange}
              placeholder="הזן את מיקום השירות"
            />
          </div>
          {/* REST TIME */}
          <div className={styles.field}>
            <label>
              זמן מנוחה/ הפסקה <span>(אופציונלי)</span>
            </label>
            <input
              type="number"
              name="restingTime"
              value={formdata.restingTime}
              onChange={handleChange}
              placeholder="הזן את זמני המנוחה לאחר השירות"
            />
          </div>
          {/* FOR SUBSCRIPTION ONLY */}
          <div className={styles.check}>
            <ReactSwitch
              name="forSubscriptionOnly"
              value={formdata.forSubscriptionOnly}
              checked={formdata.forSubscriptionOnly}
              onChange={(e) => {
                setFormdata((prev) => {
                  return {
                    ...prev,
                    forSubscriptionOnly: e,
                  };
                });
              }}
              offColor="#EBEBEB"
              onColor="#DE1468"
              checkedIcon={false}
              uncheckedIcon={false}
              className={styles.switch}
            />
            <p>כניסה למנוים בלבד</p>
          </div>
          {/* MAXIMUM QUEUE */}
          <div className={styles.numberOfCustomers}>
            <h4>
              מספר משתתפים <span>*</span>
            </h4>
            <div className={styles.counter}>
              <span
                onClick={() => {
                  setFormdata((prev) => {
                    return {
                      ...prev,
                      maximumQueue: prev.maximumQueue + 1,
                    };
                  });
                }}
              >
                +
              </span>
              <input type="text" value={formdata.maximumQueue} />
              <span
                onClick={() => {
                  setFormdata((prev) => {
                    return {
                      ...prev,
                      maximumQueue:
                        prev.maximumQueue > 1 ? prev.maximumQueue - 1 : 1,
                    };
                  });
                }}
              >
                -
              </span>
            </div>
          </div>
          <span style={{ textAlign: "center", marginBottom: "-35px" }}>
            שימו לב שאלו הגדרות בסיסיות בלבד וניתן לערוך ולהגדיר את השירות
            במערכת הניהול לאחר ההרשמה.
          </span>
          <button
            className={styles.submitBtn}
            onClick={create}
            disabled={!formdata.name || !formdata.time}
          >
            {loading ? <Spinner /> : "צור שירות חדש"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default CreateServiceCard;
