import Backdrop from "./Backdrop";
import styles from "./modal.module.css";

const Modal = ({ children, setIsOpen }) => {
  return (
    <div className={styles.mainContainer}>
      <Backdrop />
      <div className={styles.container}>
        {setIsOpen && (
          <span className={styles.close} onClick={() => setIsOpen(false)}>
            &times;
          </span>
        )}
        <div className={styles.content}>{children}</div>
      </div>
    </div>
  );
};

export default Modal;
