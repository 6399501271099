export const WorkingHoursIconSvg = ({ fill }) => {
  return (
    <svg
      width="41"
      height="40"
      viewBox="0 0 41 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.8335 3.33398V8.33398"
        stroke={fill}
        strokeWidth="1.6"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.1665 3.33398V8.33398"
        stroke={fill}
        strokeWidth="1.6"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.3335 15.1504H34.6668"
        stroke={fill}
        strokeWidth="1.6"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M30.5002 38.3333C34.1821 38.3333 37.1668 35.3486 37.1668 31.6667C37.1668 27.9848 34.1821 25 30.5002 25C26.8183 25 23.8335 27.9848 23.8335 31.6667C23.8335 35.3486 26.8183 38.3333 30.5002 38.3333Z"
        stroke={fill}
        strokeWidth="1.6"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M32.9838 31.75H28.0171"
        stroke={fill}
        strokeWidth="1.6"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M30.5007 29.3164V34.2997"
        stroke={fill}
        strokeWidth="1.6"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M35.5 14.1673V27.2673C34.2833 25.884 32.5 25.0007 30.5 25.0007C26.8167 25.0007 23.8333 27.984 23.8333 31.6673C23.8333 32.9173 24.1833 34.1006 24.8 35.1006C25.15 35.7006 25.6 36.234 26.1167 36.6673H13.8333C8 36.6673 5.5 33.334 5.5 28.334V14.1673C5.5 9.16732 8 5.83398 13.8333 5.83398H27.1667C33 5.83398 35.5 9.16732 35.5 14.1673Z"
        stroke={fill}
        strokeWidth="1.6"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.4907 22.8333H20.5057"
        stroke={fill}
        strokeWidth="2.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.3256 22.8333H14.3406"
        stroke={fill}
        strokeWidth="2.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.3256 27.8333H14.3406"
        stroke={fill}
        strokeWidth="2.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
