import { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { BASE_URL } from "../config";
import OTPInput from "otp-input-react";
import "../styles/global.css";
import Form from "../containers/home/form";
import About from "../containers/home/about";
import Modal from "../components/Modal/Modal";
import Spinner from "../components/Spinner/Spinner";
import styles from "../styles/home.module.css";

const Home = () => {
  const navigate = useNavigate();

  const [businessName, setBusinessName] = useState("");
  const [ownerName, setOwnerName] = useState("");
  const [phone, setPhone] = useState("");

  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");

  // errors
  const [otpError, setOtpError] = useState("");
  const [wrongOtp, setWrongOtp] = useState("");

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [OTP, setOTP] = useState("");
  const [loading2, setLoading2] = useState(false);

  // errors
  const [businessNameError, setBusinessNameError] = useState("");
  const [ownerNameError, setOwnerNameError] = useState("");
  const [phoneError, setPhoneError] = useState();
  const [emailError, setEmailError] = useState("");
  const [addressError, setAddressError] = useState("");

  const [alreadyRegistered, setAlreadyRegistered] = useState(false);
  const [emailExists, setEmailExists] = useState(false);
  const isValidPhoneNumber = (phoneNumber) => {
    const pattern = /^05\d{8}$/;
    return pattern.test(phoneNumber);
  };

  // const handlePhoneInputBlur = (e) => {
  //   const inputPhone = e.target.value;

  //   if (!isValidPhoneNumber(inputPhone)) {
  //     setPhoneError("יש להזין מספר תקין");
  //   } else {
  //     setPhoneError("");
  //   }
  // };

  const stepTwo = async (e) => {
    e.preventDefault();

    if (!OTP) {
      setOtpError("חובה לאמת את הטלפון הנייד");
    }

    if (OTP) {
      const payload = {
        phone,
        otp: OTP,
      };

      setLoading2(true);

      // === VERIFY OTP ===
      try {
        let res = await axios.post(`${BASE_URL}/business/otp_verify`, payload);

        if (res.status === 201) {
          localStorage.setItem("business_id", res.data.user.id);
          localStorage.setItem("auth_token", res.data.token.access.token);
          localStorage.setItem("business_code", res.data.user.businessId);

          // if its first time then update profile
          if (!res.data.user.first_time) {
            // === UPDATE BUSINESS PROFILE ===
            const payload = {
              id: res.data.user.id,
              email,
              address,
              latitude: latitude,
              longitude: longitude,
              business_name: businessName,
              name: ownerName,
              phone,
              first_time: true,
            };

            let config = {
              headers: {
                Authorization: res.data.token.access.token,
              },
            };

            try {
              let updateRes = await axios.put(
                `${BASE_URL}/business/update_profile_info`,
                payload,
                config
              );
              console.log(updateRes, "heheheh");
              if (updateRes.status === 200) {
                localStorage.setItem("ownerName", ownerName);
                setIsModalOpen(false);
                navigate("/create-service");
              }
            } catch (error) {
              if (error.response.data.message === "Email already taken") {
                setIsModalOpen(false);
                setEmailExists(true);
              }
            }
          }
          // if not first time then show already registered modal
          else {
            setIsModalOpen(false);
            setAlreadyRegistered(true);
          }
        }
      } catch (error) {
        console.log(error);
        setWrongOtp("קוד אימות שגוי, נסו שוב");
      }

      setLoading2(false);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <div className={styles.card}>
          <Form
            setIsModalOpen={setIsModalOpen}
            businessName={businessName}
            setBusinessName={setBusinessName}
            ownerName={ownerName}
            setOwnerName={setOwnerName}
            phone={phone}
            setPhone={setPhone}
            email={email}
            setEmail={setEmail}
            address={address}
            setAddress={setAddress}
            businessNameError={businessNameError}
            setBusinessNameError={setBusinessNameError}
            ownerNameError={ownerNameError}
            setOwnerNameError={setOwnerNameError}
            phoneError={phoneError}
            setPhoneError={setPhoneError}
            emailError={emailError}
            setEmailError={setEmailError}
            addressError={addressError}
            setAddressError={setAddressError}
            setLatitude={setLatitude}
            setLongitude={setLongitude}
          />
          <About />
        </div>
      </div>

      {isModalOpen && (
        <Modal setIsOpen={setIsModalOpen}>
          <div className={styles.modalWrapper}>
            <p className={styles.successText}>שלחנו קוד למספר {`${phone}`}</p>

            <div className={styles.otpContainer}>
              <OTPInput
                value={OTP}
                onChange={setOTP}
                autoFocus
                OTPLength={6}
                otpType="number"
                disabled={false}
                className={styles.otp}
                style={{ justifyContent: "center", direction: "ltr" }}
                inputStyles={{
                  marginRight: "0px",
                  margin: "0px 10px",
                  width: "50px",
                  height: "50px",
                  fontSize: "24px",
                }}
              />
              {otpError && <span className={styles.errorMsg}>{otpError}</span>}
              {wrongOtp && <span className={styles.errorMsg}>{wrongOtp}</span>}
            </div>

            <button className={styles.btn} onClick={stepTwo}>
              {loading2 ? <Spinner /> : "בדיקה"}
            </button>
          </div>
        </Modal>
      )}

      {alreadyRegistered && (
        <Modal>
          <div className={styles.already}>
            <span
              className={styles.error}
              onClick={() => window.location.reload()}
            >
              X
            </span>

            <p>נראה שמספר זה כבר קיים, הורידו את האפליקציה והתחברו למערכת</p>

            <div className={styles.store}>
              <a
                id="appclick"
                href="https://apps.apple.com/il/app/%D7%AA%D7%95%D7%A8-%D7%93%D7%99%D7%92%D7%99%D7%98%D7%9C-%D7%A0%D7%99%D7%94%D7%95%D7%9C-%D7%99%D7%95%D7%9E%D7%9F/id1602074493"
                target="_blank"
              >
                <img
                  src="/assets/appledown.svg"
                  alt="זמין להורדה ב-App Store"
                />
              </a>

              <a
                id="gclick"
                href=" https://play.google.com/store/apps/details?id=com.adminappointmentapp"
                target="_blank"
              >
                <img
                  src="/assets/googledown.svg"
                  alt="זמין להורדה ב-Google Play"
                />
              </a>
            </div>
          </div>
        </Modal>
      )}

      {emailExists && (
        <Modal>
          <div className={styles.already}>
            <span
              className={styles.error}
              style={{ cursor: "pointer" }}
              onClick={() => window.location.reload()}
            >
              X
            </span>

            <p>כתובת המייל כבר שימוש</p>

            <div className={styles.store}>
              <a
                id="appclick"
                href="https://apps.apple.com/il/app/%D7%AA%D7%95%D7%A8-%D7%93%D7%99%D7%92%D7%99%D7%98%D7%9C-%D7%A0%D7%99%D7%94%D7%95%D7%9C-%D7%99%D7%95%D7%9E%D7%9F/id1602074493"
                target="_blank"
              >
                <img
                  src="/assets/appledown.svg"
                  alt="זמין להורדה ב-App Store"
                />
              </a>

              <a
                id="gclick"
                href=" https://play.google.com/store/apps/details?id=com.adminappointmentapp"
                target="_blank"
              >
                <img
                  src="/assets/googledown.svg"
                  alt="זמין להורדה ב-Google Play"
                />
              </a>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default Home;
