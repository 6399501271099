import DayName from "./dayName";
import Hours from "./hours";
import Actions from "./actions";
import Services from "./services";
import styles from "../styles/day.module.css";
import { useState } from "react";

const Day = ({ id, dayName, status, timing, setData, services }) => {
  const [isOpen, setIsOpen] = useState(false);
  
  const defaultStartTime = "08:00";
  const addMinutesToTime = (time, minutes) => {
    const [hours, mins] = time.split(':').map(Number);
    let newMins = mins + minutes;
    let newHours = hours;

    if (newMins >= 60) {
      newHours++;
      newMins -= 60;
    }

    return `${String(newHours).padStart(2, '0')}:${String(newMins).padStart(2, '0')}`;
  };

  const handleDefaultTimes = (time) => {
    const defaultEndTime = addMinutesToTime(defaultStartTime, 15);

    setData((prev) =>
      prev.map((day) => {
        if (day._id === id) {
          return {
            ...day,
            timing: day.timing.map((slot) => {
              if (!slot.start_time) slot.start_time = defaultStartTime;
              if (!slot.end_time || slot.end_time <= addMinutesToTime(slot.start_time, 15)) {
                slot.end_time = addMinutesToTime(slot.start_time, 15);
              }
              return slot;
            }),
          };
        }
        return day;
      })
    );
  };

  return (
    <div className={styles.container}>
      {/* DAY NAME */}
      <DayName
        dayId={id}
        dayName={dayName}
        status={status}
        setData={setData}
        timing={timing}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      />

      {/* this div to show a close message on it */}
      <div className={`${styles.column} ${isOpen ? styles.open : styles.hide}`}>
        {timing &&
          timing.length > 0 &&
          timing.map((i, idx) => (
            <div key={i._id} className={styles.row}>
              <Hours
                dayId={id}
                timingIndex={idx}
                setData={setData}
                startTime={i.start_time || handleDefaultTimes(i.start_time) || defaultStartTime}
                endTime={i.end_time || handleDefaultTimes(i.end_time) || addMinutesToTime(defaultStartTime, 15)}
                timing={timing}
              />

              <Services
                allServices={services}
                services={i.services}
                dayId={id}
                timingIndex={idx}
                setData={setData}
              />

              {idx + 1 === timing.length && (
                <Actions
                  dayId={id}
                  timingIndex={idx}
                  setData={setData}
                  timing={timing}
                  allServices={services}
                />
              )}
            </div>
          ))}
      </div>
    </div>
  );
};

export default Day;
