export const AddServiceIconSvg = ({ fill }) => {
  return (
    <svg
      width="41"
      height="40"
      viewBox="0 0 41 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.8335 3.33398V8.33398"
        stroke={fill}
        strokeWidth="1.6"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.1665 3.33398V8.33398"
        stroke={fill}
        strokeWidth="1.6"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M35.5 14.1673V22.7173C34.0167 21.534 32.1333 20.834 30.0833 20.834C28.0333 20.834 26.1167 21.5506 24.6167 22.7673C22.6 24.3506 21.3333 26.834 21.3333 29.584C21.3333 31.2173 21.8 32.784 22.6 34.084C23.2167 35.1007 24.0167 35.984 24.9667 36.6673H13.8333C8 36.6673 5.5 33.334 5.5 28.334V14.1673C5.5 9.16732 8 5.83398 13.8333 5.83398H27.1667C33 5.83398 35.5 9.16732 35.5 14.1673Z"
        stroke={fill}
        strokeWidth="1.6"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.1665 18.334H22.1665"
        stroke={fill}
        strokeWidth="1.6"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.1665 26.666H16.5332"
        stroke={fill}
        strokeWidth="1.6"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M38.8335 29.584C38.8335 31.2173 38.3668 32.784 37.5668 34.084C37.1001 34.884 36.5168 35.584 35.8335 36.1507C34.3002 37.5173 32.3002 38.334 30.0835 38.334C28.1668 38.334 26.4002 37.7173 24.9668 36.6673C24.0168 35.984 23.2168 35.1007 22.6002 34.084C21.8002 32.784 21.3335 31.2173 21.3335 29.584C21.3335 26.834 22.6002 24.3506 24.6168 22.7673C26.1168 21.5506 28.0335 20.834 30.0835 20.834C32.1335 20.834 34.0168 21.534 35.5002 22.7173C37.5335 24.3173 38.8335 26.8007 38.8335 29.584Z"
        stroke={fill}
        strokeWidth="1.6"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M30.0832 33.7493C30.0832 31.4493 31.9498 29.5827 34.2498 29.5827C31.9498 29.5827 30.0832 27.716 30.0832 25.416C30.0832 27.716 28.2165 29.5827 25.9165 29.5827C28.2165 29.5827 30.0832 31.4493 30.0832 33.7493Z"
        stroke={fill}
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
