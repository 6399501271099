import { useLocation } from "react-router-dom";
import { AddServiceIconSvg } from "../../svgs/addServiceIconSvg";
import { WorkingHoursIconSvg } from "../../svgs/workingHoursIconSvg";
import { ProfileUpdateIconSvg } from "../../svgs/profileUpdateIconSvg";
import styles from "./progressBar.module.css";

const ProgressBar = () => {
  let location = useLocation();
  location = location.pathname;

  return (
    <div className={styles.container}>
      <div
        className={`${styles.box} ${
          location === "/create-service" || location === "/services"
            ? styles.active
            : ""
        } ${
          location === "/availability" || location === "/update-profile"
            ? styles.completed
            : ""
        }`}
      >
        <AddServiceIconSvg
          fill={
            location === "/create-service" ||
            location === "/services" ||
            location === "/availability" ||
            location === "/update-profile"
              ? "#DE1468"
              : "#292D32"
          }
        />
        <p>הוספת שירות</p>
      </div>

      <span className={styles.line} />

      <div
        className={`${styles.box} ${
          location === "/availability" ? styles.active : ""
        } ${location === "/update-profile" ? styles.completed : ""}`}
      >
        <WorkingHoursIconSvg
          fill={
            location === "/availability" || location === "/update-profile"
              ? "#DE1468"
              : "#292D32"
          }
        />
        <p>ימי קבלה</p>
      </div>

      <span className={styles.line} />

      <div
        className={`${styles.box} ${
          location === "/update-profile" ? styles.active : ""
        }`}
      >
        <ProfileUpdateIconSvg
          fill={location === "/update-profile" ? "#DE1468" : "#292D32"}
        />
        <p>הקמת כרטיס</p>
      </div>
    </div>
  );
};

export default ProgressBar;
