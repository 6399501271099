import React,{ useEffect,useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../components/Header/Header";
import ProgressBar from "../components/ProgressBar";
import styles from "../styles/success.module.css";
import ConfettiExplosion from 'react-confetti-explosion';

const Success = () => {
  const navigate = useNavigate();
  const [isExploding, setIsExploding] = React.useState(true);
  // REDIRECT IF NO TOKEN
  useEffect(() => {
    let token = localStorage.getItem("auth_token");
    if (!token) {
      navigate("/register");
    }
  }, []);

  let businessCode = localStorage.getItem("business_code");
  let ownerName = localStorage.getItem("ownerName");
  const confettiOptions = {
    force: 0.8,
    duration: 5000,
    particleCount: 250,
    width: 1600,
  };
  
  return (
    <div className={styles.container}>
           {isExploding && <ConfettiExplosion {...confettiOptions} />}

      <div className={styles.card}>
        <div className={styles.top}>
          <img
            src="/assets/success-graphic.svg"
            alt="success-graphic"
            className={styles.img}
          />

          <h2 className={styles.heading}>זהו סיימנו!</h2>
          <p className={styles.text}>העסק שלך נרשם בהצלחה!</p>

          <div className={styles.textBox}>
            <p className={styles.textOne}>
              מזל טוב! ההרשמה הושלמה בהצלחה והעסק שלכם מוכן לפעולה{" "}
              <span>
                כעת נותר רק להתחבר לאפליקציה ולהתחיל להוסיף לקוחות לעסק!
              </span>
            </p>
            <p className={styles.textTwo}>
              הקישור לעסק שלכם כבר מוכן וניתן לצפות בו בקישור הבא:
            </p>
            <p className={styles.copy}>
              <a
                style={{
                  textDecoration: "none",
                  color: "#084a04",
                  fontSize: "22px",
                }}
                target="_blank"
                href={`http://app.tor.digital/${businessCode}`}
              >
                <span>https://app.tor.digital/{businessCode}</span>
              </a>
            </p>
          </div>

          <div className={styles.store}>
            <h4>קדימה, להוריד את האפליקציה שלנו!</h4>
            <p>
            יאללה, להוריד את האפליקציה, להתחבר עם מספר הטלפון שלך, לשתף עם
                לקוחות ולחסוך טלפונים וכאב ראש של קביעת תורים
            </p>
            <div className={styles.imgs}>
              <a
                id="appclick"
                href="https://apps.apple.com/il/app/%D7%AA%D7%95%D7%A8-%D7%93%D7%99%D7%92%D7%99%D7%98%D7%9C-%D7%A0%D7%99%D7%94%D7%95%D7%9C-%D7%99%D7%95%D7%9E%D7%9F/id1602074493"
                target="_blank"
              >
                <img
                  src="/assets/appledown.svg"
                  alt="זמין להורדה ב-App Store"
                />
              </a>

              <a
                id="gclick"
                href=" https://play.google.com/store/apps/details?id=com.adminappointmentapp"
                target="_blank"
              >
                <img
                  src="/assets/googledown.svg"
                  alt="זמין להורדה ב-Google Play"
                />
              </a>
            </div>
          </div>
        </div>
{/* 
        <div className={styles.bottom}>
          <button>המשך</button>
        </div> */}
      </div>

      {/* <div className={styles.wrapper}>
        <main className={styles.main} style={{ direction: "rtl" }}>
          <img
            src="/assets/success.gif"
            alt="success"
            style={{ width: "200px" }}
          />
          <div style={{ textAlign: "center" }}>
            <h2>{ownerName} העסק שלך נרשם בהצלחה</h2>
            <div
              style={{
                backgroundColor: "#fafdff",
                padding: "20px",
                border: "2px dashed rgb(46 113 84 / 55%)",
                margin: "20px 0",
              }}
            >
              <p
                style={{
                  fontSize: "18px",
                }}
              >
                {" "}
                האתר שלך כבר מוכן ללקוחות (כמעט😇)
                <span style={{ fontWeight: "600" }}>
                  {" "}
                  נותר לך רק להוסיף תמונות וטקסט קצר על העסק בתוך האפליקציה
                </span>
              </p>
              <br />
              <a
                style={{
                  textDecoration: "none",
                  color: "#084a04",
                  fontSize: "24px",
                }}
                target="_blank"
                href={`http://app.tor.digital/${businessCode}`}
              >
                <span style={{ lineHeight: "24px", fontWeight: "600" }}>
                  מתרגשים להציג לך את האתר החדש שלך ממנו לקוחות יוכלו לקבוע אליך
                  תור:
                </span>{" "}
                <span style={{ color: "blue", textDecoration: "underline" }}>
                  app.tor.digital/{businessCode}
                </span>
              </a>
            </div>
            <h3 style={{ marginTop: "15px", marginBottom: "15px" }}>
              <span style={{ fontWeight: "600" }}>
                יאללה, להוריד את האפליקציה, להתחבר עם מספר הטלפון שלך, לשתף עם
                לקוחות ולחסוך טלפונים וכאב ראש של קביעת תורים
              </span>

              <br />
              <span style={{ fontSize: "30px" }}>👇👇👇</span>
            </h3>

            <div className={styles.store}>
              <a
                id="appclick"
                href="https://apps.apple.com/il/app/%D7%AA%D7%95%D7%A8-%D7%93%D7%99%D7%92%D7%99%D7%98%D7%9C-%D7%A0%D7%99%D7%94%D7%95%D7%9C-%D7%99%D7%95%D7%9E%D7%9F/id1602074493"
                target="_blank"
              >
                <img
                  src="/assets/appledown.svg"
                  alt="זמין להורדה ב-App Store"
                />
              </a>

              <a
                id="gclick"
                href=" https://play.google.com/store/apps/details?id=com.adminappointmentapp"
                target="_blank"
              >
                <img
                  src="/assets/googledown.svg"
                  alt="זמין להורדה ב-Google Play"
                />
              </a>
            </div>
          </div>
        </main>
      </div> */}
    </div>
  );
};

export default Success;
