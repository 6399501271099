import { useEffect, useRef, useState } from "react";
import axios from "axios";
import { BASE_URL } from "../../config";
import Spinner from "../../components/Spinner/Spinner";
import styles from "../../styles/containers/home/form.module.css";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";

const Form = ({
  setIsModalOpen,
  businessName,
  setBusinessName,
  ownerName,
  setOwnerName,
  phone,
  setPhone,
  email,
  setEmail,
  address,
  setAddress,
  businessNameError,
  setBusinessNameError,
  ownerNameError,
  setOwnerNameError,
  phoneError,
  setPhoneError,
  emailError,
  setEmailError,
  addressError,
  setAddressError,
  setLatitude,
  setLongitude,
}) => {
  const [loading1, setLoading1] = useState(false);

  const isValidPhoneNumber = (phoneNumber) => {
    const pattern = /^05\d{8}$/;
    return pattern.test(phoneNumber);
  };

  const handlePhoneInputChange = (e) => {
    const inputPhone = e.target.value;
    setPhone(inputPhone);
  };

  const stepOne = async (e) => {
    e.preventDefault();

    if (!businessName) {
      setBusinessNameError(" אנו צריכים לדעת את שם העסק :)");
    }
    if (!ownerName) {
      setOwnerNameError("נשמח לדעת עם מי יש לנו עסק😅");
    }
    if (!phone) {
      setPhoneError("כמעט שכחת להזין מספר טלפון");
    }
    if (!email) {
      setEmailError("כתובת מייל זהו שדה חובה");
    }
    if (!address) {
      setAddressError("כתובת זהו שדה חובה");
    }

    if (businessName && ownerName && phone && email && address) {
      const payload = {
        phone,
        business_phone: phone,
      };

      setLoading1(true);

      try {
        const res = await axios.post(`${BASE_URL}/business/login`, payload);
        if (res.status === 201) {
          setIsModalOpen(true);
        }
      } catch (error) {
        console.log(error);
      }

      setLoading1(false);
    }
  };

  // HANDLE GOOGLE PLACES API
  const addressInputRef = useRef(null);

  useEffect(() => {
    const autocomplete = new window.google.maps.places.Autocomplete(
      addressInputRef.current,
      { types: ["geocode"], componentRestrictions: { country: "IL" } }
    );

    autocomplete.addListener("place_changed", () => {
      const selectedPlace = autocomplete.getPlace();

      setAddress(selectedPlace.formatted_address || "");
      setLatitude(selectedPlace.geometry.location.lat().toString() || "");
      setLongitude(selectedPlace.geometry.location.lng().toString() || "");
    });

    return () => {
      window.google.maps.event.clearInstanceListeners(addressInputRef.current);
    };
  }, []);

  return (
    <div className={styles.container}>
      <img src="/assets/logo-1.png" alt="logo" className={styles.logo} />

      <div className={styles.mobileText}>
        <h2>ברוכים הבאים לTOR DIGITAL</h2>
        <p>
          אפליקציה לקביעת וזימון תורים - תור דיגיטל מתאים לקוסמטיקאיות, ספרים,
          מאמני כושר וכל מי שמקבל לקוחות.. הלקוחות קובעים אליך תור בפחות מדקה
        </p>
      </div>

      <h2>צור חשבון חדש</h2>
      <p>תוך 2 דקות תוכלו לאפשר ללקוחות שלכם לקבוע תור אונליין</p>

      <form className={styles.form} onSubmit={stepOne}>
        <div className={styles.field}>
          <label>
            שם העסק
            <span> *</span>
          </label>
          <input
            type="text"
            name="businessName"
            placeholder="שם העסק"
            value={businessName}
            onChange={(e) => setBusinessName(e.target.value)}
          />
          {businessNameError && (
            <small className={styles.errorMsg}>{businessNameError}</small>
          )}
        </div>

        <div className={styles.field}>
          <label>
            השם שלך
            <span> *</span>
          </label>
          <input
            type="text"
            name="ownerName"
            placeholder="שם מלא"
            value={ownerName}
            onChange={(e) => setOwnerName(e.target.value)}
          />
          {ownerNameError && (
            <small className={styles.errorMsg}>{ownerNameError}</small>
          )}
        </div>

        <div className={styles.field}>
          <label>
            כתובת מייל
            <span> *</span>
          </label>
          <input
            type="text"
            name="email"
            placeholder="כתובת המייל שלך"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          {emailError && (
            <small className={styles.errorMsg}>{emailError}</small>
          )}
        </div>

        <div className={styles.field}>
          <label>
            טלפון
            <span> *</span>
          </label>
          <input
            type="number"
            name="phone"
            placeholder="מספר טלפון"
            value={phone}
            onChange={handlePhoneInputChange}
            // onBlur={handlePhoneInputBlur}
          />
          {phoneError && (
            <small className={styles.errorMsg}>{phoneError}</small>
          )}
        </div>
        <div className={styles.field}>
          <label>
            כתובת של העסק (נא להקליד ולבחור מהרשימה בלבד)
            <span> *</span>
          </label>
          <input
            type="text"
            name="address"
            placeholder="כתובת עסק מלאה כולל עיר, רחוב ומספר"
            ref={addressInputRef}
          />
          {addressError && (
            <small className={styles.errorMsg}>{addressError}</small>
          )}
        </div>
        <button type="submit" className={styles.btn}>
          {loading1 ? <Spinner /> : "הרשמה לשירות"}
        </button>
      </form>
    </div>
  );
};

export default Form;
