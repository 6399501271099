import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../components/Header/Header";
import Service from "../components/Service/Service";
import useServices from "../hooks/useServices";
import ProgressBar from "../components/ProgressBar";
import styles from "../styles/services.module.css";

const Services = () => {
  const navigate = useNavigate();

  // REDIRECT IF NO TOKEN
  useEffect(() => {
    let token = localStorage.getItem("auth_token");
    if (!token) {
      navigate("/register");
    }
  }, []);

  const [services] = useServices();

  if (!services) {
    return (
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <Header />
          טוען...
        </div>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <ProgressBar currentStep={1} />
        <main className={styles.main} style={{ direction: "rtl" }}>
          <img
            src="/assets/services-graphic.png"
            alt="back-to-website"
            className={styles.img}
          />

          <h1 className={styles.heading}>השירות התווסף!</h1>
          <p className={styles.text}>ניתן ליצור שירותים נוספים</p>

          {services.length === 0 ? (
            // IF THERE ARE NO SERVICES THEN SHOW THIS MESSAGE
            <>
              <h4 style={{ fontSize: "20px", fontWeight: "600" }}>
                עוד שתי הגדרות וסיימנו! 🙂
              </h4>
              <h3>
                כאן מוסיפים את השירותים שאתם נותנים, למשל: גבות, תספורת + זקן,
                לק ג'ל וכו'..
              </h3>
            </>
          ) : (
            // IF THERE ARE SERVICES
            <div className={styles.services}>
              {services.map((i) => (
                <Service
                  id={i._id}
                  key={i._id}
                  time={i.time}
                  name={i.name}
                  price={i.price}
                  isExactPrice={i.isExactPrice}
                  description={i.description}
                />
              ))}
            </div>
          )}
          {services.length == 0 && (
            <button
              className={styles.addService}
              onClick={() => navigate("/create-service")}
            >
              הוסף שירות
            </button>
          )}
          {services.length > 0 && (
            <button
              className={styles.addService}
              onClick={() => navigate("/create-service")}
            >
              הוספת שירות נוסף
            </button>
          )}
          {services.length > 0 && (
            <button
              className={styles.next}
              onClick={() => navigate("/availability")}
            >
              המשך
            </button>
          )}
          {/* <h3 style={{ fontSize: "15px", marginTop: "20px" }}>
            ניתן לשנות את ההגדרות והשירותים לאחר סיום ההרשמה באפליקציה
          </h3> */}
        </main>
      </div>
    </div>
  );
};

export default Services;

// If there is not service at all and you are about to add your first than show on button the text:
// הוסף שירות

// If there is more than 1 service, than show:
// הוסף שירות נוסף
