const style = {
  height: "100vh",
  width: "100%",
  position: "fixed",
  left: "0px",
  top: "0px",
  backdropFilter: "blur(3px)",
};

const Backdrop = () => <div style={style}></div>;

export default Backdrop;
