import { hoursAndMinutes } from "../utils/hoursAndMinutes";
import { toast } from "react-toastify";
import styles from "../styles/hours.module.css";

const Hours = ({
  startTime = "08:00",
  endTime = "18:00",
  setData,
  timingIndex,
  dayId,
  timing,
}) => {
  // HANDLE START TIME CHANGE
  const handleStartTimeChange = (e) => {
    // setData((prev) =>
    //   prev.map((obj) => {
    //     if (obj._id === dayId) {
    //       return { ...obj, status: true };
    //     }
    //     return obj;
    //   })
    // );

    setData((prev) =>
      prev.map((obj) => {
        if (obj._id === dayId) {
          return {
            ...obj,
            timing: obj.timing.map((x, idxx) => {
              if (timingIndex === idxx) {
                // check if end time exists
                if (x.end_time) {
                  // check if its the first item of array
                  if (idxx === 0) {
                    // check if start time is greater than end time
                    if (e.target.value < x.end_time) {
                      return {
                        ...x,
                        start_time: e.target.value,
                      };
                    } else {
                      toast.error("יש להזין זמן קטן יותר מזמן הסיום");
                    }
                  } else {
                    // check if start time is greater than end time and previous item's end time
                    if (
                      e.target.value < x.end_time &&
                      e.target.value > timing[idxx - 1].end_time
                    ) {
                      return {
                        ...x,
                        start_time: e.target.value,
                      };
                    } else {
                      toast.error("יש להזין זמן קטן יותר מזמן הסיום");
                    }
                  }
                } else {
                  if (idxx === 0) {
                    return {
                      ...x,
                      start_time: e.target.value,
                    };
                  } else {
                    if (e.target.value > timing[idxx - 1].end_time) {
                      return {
                        ...x,
                        start_time: e.target.value,
                      };
                    } else {
                      toast.error("זמן החלון חייב להיות גדול מזמן ההתחלה");
                    }
                  }
                }
              }
              return x;
            }),
          };
        }
        return obj;
      })
    );
  };

  // ADD VALIDATION -----------

  // HANDLE END TIME CHANGE
  const handleEndTimeChange = (e) => {
    // setData((prev) =>
    //   prev.map((obj) => {
    //     if (obj._id === dayId) {
    //       return { ...obj, status: true };
    //     }
    //     return obj;
    //   })
    // );

    setData((prev) =>
      prev.map((obj) => {
        if (obj._id === dayId) {
          return {
            ...obj,
            timing: obj.timing.map((x, idxx) => {
              if (timingIndex === idxx) {
                // check if start time exists
                if (x.start_time) {
                  // check if end time is greater than start time
                  if (e.target.value > x.start_time) {
                    return {
                      ...x,
                      end_time: e.target.value,
                    };
                  } else {
                    toast.error("זמן ההתחלה חייב להיות קטן מהזמן הסיום");
                  }
                } else {
                  return {
                    ...x,
                    end_time: e.target.value,
                  };
                }
              }
              return x;
            }),
          };
        }
        return obj;
      })
    );
  };

  return (
    <div className={styles.container}>
      <p className={styles.text}>שעות קבלה:</p>
      <div className={styles.slots}>
        <select
          name="endTime"
          value={endTime}
          className={`${styles.slot} ${styles.active}`}
          onChange={handleEndTimeChange}
        >
          <option value="" selected disabled>
            - -
          </option>
          {hoursAndMinutes.map((i) => (
            <option value={i} key={i}>
              {i}
            </option>
          ))}
        </select>

        <span>-</span>

        <select
          name="startTime"
          value={startTime}
          className={`${styles.slot} ${styles.active}`}
          onChange={handleStartTimeChange}
        >
          <option value="" selected disabled>
            - -
          </option>
          {hoursAndMinutes.map((i) => (
            <option value={i} key={i}>
              {i}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default Hours;
