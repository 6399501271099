import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styles from "../../styles/containers/home/about.module.css";

const About = () => {
  const settings = {
    dots: true,
    infinite: true,   // This enables the loop
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <img src="/assets/next-icon.svg" alt="next-icon" />,
    prevArrow: <img src="/assets/prev-icon.svg" alt="prev-icon" />,
    autoplay: true,   // This enables the autoplay
    autoplaySpeed: 5000,  // Delay of 3 seconds; you can adjust this value as per your needs
  };
  
  return (
    <div className={styles.container}>
      <img src="/assets/home-bg-1.png" alt="card-bg" className={styles.bg} />
      <h2 className={styles.heading}>ברוכים הבאים לTOR DIGITAL</h2>
      <p className={styles.text}>
        אפליקציה לקביעת וזימון תורים - תור דיגיטל מתאים לקוסמטיקאיות, ספרים,
        מאמני כושר וכל מי שמקבל לקוחות.. הלקוחות קובעים אליך תור בפחות מדקה
      </p>

      {/* FEATURES */}
      <div className={styles.features}>
        <Slider {...settings}>
          <div className={styles.feature}>
            <img src="/assets/feature-img-1.svg" alt="feature-img-1" />
            <h3>?לאיזה עסקים השירות מתאים</h3>
            <p>
              האפליקציה שלנו מתאימה לכל בעל עסק המקבל תורים, החל מבונות
              ציפורניים, מעצבות גבות, דוגיסיטר, מאפרות, מורים פרטיים, מאמני כושר
              ועד קליניקות המקבלות מטופלים
            </p>
          </div>
          <div className={styles.feature}>
            <img src="/assets/feature-img-1.svg" alt="feature-img-1" />
            <h3>שירות אנושי זמין ומקצועי</h3>
            <p>
              אנחנו מציעים שירות לקוחות מקצועי וזמין בטלפון, וואטסאפ ומייל.
              נמצאים כאן לעזרה, לשאלות ולכל צורך שיתקום.
            </p>
          </div>
          <div className={styles.feature}>
            <img src="/assets/feature-img-1.svg" alt="feature-img-1" />
            <h3>שימור לקוחות בדרך הטובה ביותר</h3>
            <p>
              האפליקציה שולחת תזכורות ללקוחות אבל לא רק. היא תדאג לשלוח מזל טוב
              בימי הולדת, בחגים וכל הודעה שתרצו בעצם (כמו: ״אני אהיה בחופשה
              בשבוע הבא ולא אקבל תורים״)
            </p>
          </div>
        </Slider>
      </div>

      {/* TESTIMONIALS */}
      <div className={styles.testimonials}>
        <div className={styles.testimonial}>
          <img src="/assets/testimonial-img-1.svg" alt="testimonial-pic" />
          <div className={styles.rating}>
            <img src="/assets/star-icon-1.svg" alt="star-icon" />
            <img src="/assets/star-icon-1.svg" alt="star-icon" />
            <img src="/assets/star-icon-1.svg" alt="star-icon" />
            <img src="/assets/star-icon-1.svg" alt="star-icon" />
            <img src="/assets/star-icon-1.svg" alt="star-icon" />
          </div>
          <p>
            האפליקציה נוחה לשימוש, הלקוחות קובעות לעצמן וחוסכות לי את כאבי ראש,
            אפשרי לשלוח הודעה יום לפני ללקוחות. אהבתי ממש. ממליצה בחום!
          </p>
          <hr />
          <small>יסכה גבות</small>
        </div>
      </div>
    </div>
  );
};

export default About;
