import { hebrewDayNameGenerator } from "../utils/hebrewDayNameGenerator";
import Day from "./day";
import Spinner from "../components/Spinner/Spinner";
import styles from "../styles/createWorkingHoursCard.module.css";

const CreateWorkingHoursCard = ({
  data,
  setData,
  setIsModalOpen,
  loading,
  services,
  handleSave,
}) => {
  return (
    <div className={styles.container}>
      <h2>
        בחירת ימי עבודה,
        <br />
        שעות קבלת קהל והשירותים
      </h2>
      <div className={styles.days}>
        {data?.map((i) => (
          <Day
            key={i._id}
            id={i._id}
            dayName={hebrewDayNameGenerator(i.name)}
            status={i.status}
            timing={i.timing}
            setData={setData}
            services={services}
          />
        ))}
      </div>
      <button className={styles.save} onClick={handleSave}>
        {loading ? <Spinner /> : "סיום והקמת עסק "}
      </button>
    </div>
  );
};

export default CreateWorkingHoursCard;
