import { AddPlusSvg } from "../svgs/addPlusSvg";
import styles from "../styles/actions.module.css";
import { toast } from "react-toastify";

const Actions = ({ dayId, timingIndex, setData, timing, allServices }) => {
  // ADD
  const handleAdd = () => {
    setData((prev) =>
      prev.map((obj) => {
        if (obj._id === dayId) {
          if (
            timing[timing.length - 1].start_time &&
            timing[timing.length - 1].end_time &&
            timing[timing.length - 1].services.length > 0
          ) {
            return {
              ...obj,
              timing: [
                ...obj.timing,
                {
                  end_time: "",
                  start_time: timing[timing.length - 1].end_time,
                  services: allServices?.map((x) => {
                    return {
                      _id: x._id,
                      status: true,
                      id: x._id,
                    };
                  }),
                },
              ],
            };
          } else {
            toast.error("נא לוודא שכל השדות תקינים ובחרתם לפחות שירות אחד");
          }
        }
        return obj;
      })
    );
  };

  // REMOVE
  const handleRemove = () => {
    setData((prev) =>
      prev.map((obj) => {
        if (obj._id === dayId) {
          return {
            ...obj,
            timing: obj.timing.filter((x, idxx) => {
              return timingIndex !== idxx;
            }),
          };
        }
        return obj;
      })
    );
  };

  return (
    <div className={styles.container}>
      {timingIndex !== 0 && (
        <div className={styles.block} onClick={handleRemove}>
          <img src="/assets/remove-icon-1.svg" alt="remove-icon" />
          <p>מחיקה</p>
        </div>
      )}
      <div className={styles.block} onClick={handleAdd}>
        <AddPlusSvg fill="#06A816" />
        <p>הוספת חלון זמן</p>
      </div>
    </div>
  );
};

export default Actions;
