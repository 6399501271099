import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import CreateService from "../pages/CreateService";
import Home from "../pages/Home";
import Services from "../pages/Services";
import Success from "../pages/Success";
import Availability from "../pages/Availability";
import Header from "../components/Header/Header";
import UpdateProfile from "../pages/UpdateProfile";

const AppRoutes = () => {
  return (
    <>
    <Router>
    <Header />
      <Routes>
        <Route exact path="/" element={<Navigate to="/register" replace />} />
        <Route exact path="/register" element={<Home />} />
        <Route exact path="/services" element={<Services />} />
        <Route exact path="/create-service" element={<CreateService />} />
        <Route exact path="/availability" element={<Availability />} />
        <Route exact path="/update-profile" element={<UpdateProfile />} />
        <Route exact path="/register/success" element={<Success />} />
      </Routes>
    </Router>
    </>
  );
};

export default AppRoutes;
