import { useEffect, useState } from "react";
import axios from "axios";
import { BASE_URL } from "../config";

const useServices = () => {
  const [services, setServices] = useState(null);

  const businessID = localStorage.getItem("business_id");
  const authToken = localStorage.getItem("auth_token");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const config = {
          headers: {
            Authorization: authToken,
          },
        };
        const res = await axios.get(
          `${BASE_URL}/services/get_services/${businessID}`,
          config
        );
        setServices(res.data.data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  return [services];
};

export default useServices;
