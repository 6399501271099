import { useLocation } from "react-router-dom";
import styles from "./header.module.css";

const Header = () => {
  const location = useLocation();
  const path = location.pathname;

  return (
    <header className={styles.header}>
      <div className={styles.right}>
        {/* {path === "/register" && (
          <a href="https://tor.digital">
            <span
              style={{
                color: "rgb(255 255 255)",
                backgroundColor: " #33a15a",
                padding: " 10px",
                borderRadius: " 5px",
                background:
                  "linear-gradient(89deg, #CC1480 21%, #D92776 22%, #D92776 40%, #E6396C 41%, #E6396C 59%, #F24C62 60%, #F24C62 78%, #F24C62 79%), #D92776",
                borderRadius: "5px",
                fontWeight: "700",
                fontSize: "18px",
                letterSpacing: " 0.5px",
                textShadow: " 2px 0px 3px rgb(67 67 67 / 56%)",
              }}
            >
              אני כבר רשום
            </span>
          </a>
        )}
      </div> */}
        <div className={styles.logo}>
          <img src="/assets/white-logo.png" alt="logo" />
        </div>
      </div>
    </header>
  );
};

export default Header;
