import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { BASE_URL } from "../config";
import Spinner from "../components/Spinner/Spinner";
import ProgressBar from "../components/ProgressBar";
import CreateWorkingHoursCard from "../containers/createWorkingHoursCard";
import { EyeIconSvg } from "../svgs/eyeIconSvg";
import { EditIconSvg } from "../svgs/editIconSvg";
import Modal from "../components/Modal/Modal";
import styles from "../styles/availability.module.css";
import useServices from "../hooks/useServices";

const Availability = () => {
  const navigate = useNavigate();

  // REDIRECT IF NO TOKEN
  useEffect(() => {
    let token = localStorage.getItem("auth_token");
    if (!token) {
      navigate("/register");
    }
  }, []);

  const [data, setData] = useState(null);

  const [days, setDays] = useState(null);

  const [daysInOrder, setDaysInOrder] = useState(null);

  const businessID = localStorage.getItem("business_id");
  const authToken = localStorage.getItem("auth_token");

  const [loading, setLoading] = useState(false);

  const [view, setView] = useState(0);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [isBelow992px, setIsBelow992px] = useState(window.innerWidth < 992);

  useEffect(() => {
    const handleResize = () => {
      setIsBelow992px(window.innerWidth < 992);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // fetch services
  const [services] = useServices();

  // call get schedule API
  // useEffect(() => {
  //   const fetchData = async () => {
  //     const config = {
  //       headers: {
  //         Authorization: authToken,
  //       },
  //     };

  //     try {
  //       const res = await axios.get(
  //         `${BASE_URL}/schedule/get_schedule/${businessID}`,
  //         config
  //       );

  //       setData(res.data.data.data.days);
  //     } catch (error) {
  //       console.log(error, "ERROR");

  //       // IF SCHEDULE DO NOT EXIST THEN CREATE IT
  //       if (error.response.status === 400) {
  //         const payload = {
  //           employeeId: businessID,
  //           days: initialSchedule,
  //         };

  //         const config = {
  //           headers: {
  //             Authorization: authToken,
  //           },
  //         };

  //         const res = await axios.post(
  //           `${BASE_URL}/schedule/create`,
  //           payload,
  //           config
  //         );
  //         if (res.status === 201) {
  //           window.location.reload();
  //         }
  //       }
  //     }
  //   };

  //   fetchData();
  // }, []);

  // FETCH SCHEDULE
  useEffect(() => {
    const fetchData = async () => {
      // setDaysLoading(true);
      const config = {
        headers: {
          Authorization: authToken,
        },
      };

      try {
        const res = await axios.get(
          `${BASE_URL}/schedule/get_schedule/${businessID}`,
          config
        );
        setDays(res.data.data.days);
        if (res.code === 200) {
          let formattedDays = res.data.data.days.map((i) => i.name);

          let a1 = [
            "monday",
            "tuesday",
            "wednesday",
            "thursday",
            "friday",
            "saturday",
            "sunday",
          ];
          let a2 = formattedDays;

          let missingDays = a1.filter((e) => !a2.includes(e));

          let payload = {
            employeeId: businessID,
            days: [],
          };
          if (missingDays.length > 0) {
            missingDays.map((day) => {
              payload.days.push({
                name: day,
                status: false,
                timing: [
                  {
                    start_time: "",
                    end_time: "",
                    services: services?.map((x) => {
                      return {
                        _id: x._id,
                        status: true,
                        id: x._id,
                      };
                    }),
                  },
                ],
              });
            });

            if (res.data.data.days.length > 0) {
              payload.days = [...payload.days, ...res.data.data.days];
            }

            const res2 = await axios.post(
              `${BASE_URL}/schedule/create`,
              payload,
              config
            );
            if (res2.status === 201) {
              window.location.reload();
            }
          }
        }
      } catch (error) {
        console.log(error);
        if (error.response.data.result.statusCode === 404) {
          const payload = {
            employeeId: businessID,
            days: [
              {
                name: "monday",
                status: false,
                timing: [
                  {
                    start_time: "",
                    end_time: "",
                    services: services?.map((x) => {
                      return {
                        _id: x._id,
                        status: true,
                        id: x._id,
                      };
                    }),
                  },
                ],
              },
              {
                name: "tuesday",
                status: false,
                timing: [
                  {
                    start_time: "",
                    end_time: "",
                    services: services?.map((x) => {
                      return {
                        _id: x._id,
                        status: true,
                        id: x._id,
                      };
                    }),
                  },
                ],
              },
              {
                name: "wednesday",
                status: false,
                timing: [
                  {
                    start_time: "",
                    end_time: "",
                    services: services?.map((x) => {
                      return {
                        _id: x._id,
                        status: true,
                        id: x._id,
                      };
                    }),
                  },
                ],
              },
              {
                name: "thursday",
                status: false,
                timing: [
                  {
                    start_time: "",
                    end_time: "",
                    services: services?.map((x) => {
                      return {
                        _id: x._id,
                        status: true,
                        id: x._id,
                      };
                    }),
                  },
                ],
              },
              {
                name: "friday",
                status: false,
                timing: [
                  {
                    start_time: "",
                    end_time: "",
                    services: services?.map((x) => {
                      return {
                        _id: x._id,
                        status: true,
                        id: x._id,
                      };
                    }),
                  },
                ],
              },
              {
                name: "saturday",
                status: false,
                timing: [
                  {
                    start_time: "",
                    end_time: "",
                    services: services?.map((x) => {
                      return {
                        _id: x._id,
                        status: true,
                        id: x._id,
                      };
                    }),
                  },
                ],
              },
              {
                name: "sunday",
                status: false,
                timing: [
                  {
                    start_time: "",
                    end_time: "",
                    services: services?.map((x) => {
                      return {
                        _id: x._id,
                        status: true,
                        id: x._id,
                      };
                    }),
                  },
                ],
              },
            ],
          };

          const res = await axios.post(
            `${BASE_URL}/schedule/create`,
            payload,
            config
          );
          if (res.status === 201) {
            window.location.reload();
          }
          console.log(res, "ERROR CREATE");
        }
      }
      // setDaysLoading(false);
    };

    if (services) {
      fetchData();
    }
  }, [services]);

  // RE-ARRANGE DAYS
  useEffect(() => {
    if (days && days.length === 7) {
      const dayOrder = [
        "sunday",
        "monday",
        "tuesday",
        "wednesday",
        "thursday",
        "friday",
        "saturday",
      ];
      function compareDays(a, b) {
        return dayOrder.indexOf(a.name) - dayOrder.indexOf(b.name);
      }
      let newArr = days.sort(compareDays);

      // ADD LEADING ZERO
      newArr.forEach(function (innerArray) {
        innerArray.timing.forEach(function (obj) {
          if (obj.start_time.length === 4) {
            obj.start_time = "0" + obj.start_time;
          }

          if (obj.end_time.length === 4) {
            obj.end_time = "0" + obj.end_time;
          }
        });
      });

      setDaysInOrder(newArr);
    }
  }, [days]);

  if (!daysInOrder) {
    return (
      <div className={styles.container}>
        <div className={styles.wrapper}>טוען...</div>
      </div>
    );
  }

  // save schedule
  const saveSchedule = async () => {
    setLoading(true);
    try {
      const payload = {
        employeeId: businessID,
        days: daysInOrder,
      };

      const config = {
        headers: {
          Authorization: authToken,
        },
      };

      const res = await axios.post(
        `${BASE_URL}/schedule/create`,
        payload,
        config
      );
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  return (
    <div className={styles.container}>
      <ProgressBar />
      {/* <div className={styles.toggleView}>
        <div className={styles.box} onClick={() => setView(0)}>
          <EditIconSvg fill={view === 0 ? "#DE1468" : "#000000"} />
        </div>
        <div className={styles.box} onClick={() => setView(1)}>
          <EyeIconSvg fill={view === 1 ? "#DE1468" : "#000000"} />
        </div>
      </div> */}
      <div className={styles.wrapper}>
        <CreateWorkingHoursCard
          data={daysInOrder}
          setData={setDaysInOrder}
          setIsModalOpen={setIsModalOpen}
          loading={loading}
          services={services}
          handleSave={() => {
            saveSchedule();
            navigate("/update-profile");
          }}
        />

        {/* PREVIEW -  FOR LATER */}
        {/* <div className={styles.verticalLine} />

        <div
          className={styles.preview}
          style={{
            display: isBelow992px && view === 0 ? "none" : "flex",
          }}
        >
          <h2 className={styles.previewMainHeading}>תצוגה מקדימה של העסק</h2>
          <h2 className={styles.scheduleHeading}>שעות פעילות</h2>
          <div className={styles.card}>
            <div className={styles.days}>
              {daysInOrder?.map((i) => (
                <div className={styles.day}>
                  <p className={styles.name}>
                    {i.name === "sunday"
                      ? "יום ראשון"
                      : i.name === "monday"
                      ? "יום שני"
                      : i.name === "tuesday"
                      ? "יום שלישי"
                      : i.name === "wednesday"
                      ? "יום רביעי"
                      : i.name === "thursday"
                      ? "יום חמישי"
                      : i.name === "friday"
                      ? "יום שישי"
                      : i.name === "saturday"
                      ? "יום שבת"
                      : ""}
                  </p>
                  <div className={styles.timings}>
                    {i.timing && i.status ? (
                      i.timing.map((j, idxx) => (
                        <div key={idxx} className={styles.time}>
                          {i.timing.every(
                            (x) => !x.end_time && !x.start_time
                          ) ? (
                            <p style={{ textAlign: "center", width: "100%" }}>
                              סגור
                            </p>
                          ) : !j.end_time && !j.start_time ? null : (
                            <>
                              <img src="/assets/clock.svg" alt="clock" />
                              <p>
                                {j.end_time} - {j.start_time}
                              </p>
                            </>
                          )}
                        </div>
                      ))
                    ) : (
                      <p style={{ textAlign: "center", width: "100%" }}>סגור</p>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div> */}
      </div>

      {/* CONFIRMATION MODAL */}
      {/* {isModalOpen && (
        <Modal setIsOpen={setIsModalOpen}>
          <div className={styles.modalWrapper}>
            <h3>
              שניה לפני שמסיימים, ניתן גם להוסיף תיאור ותמונות של העסק ללקוחות
              שלכם. זה לוקח דקה ולאחר מכן נציג לכם את האתר שלכם
            </h3>
            <div className={styles.btns}>
              <button
                className={styles.moreBtn}
                onClick={() => {
                  saveSchedule();
                  navigate("/update-profile");
                }}
              >
                אני רוצה
              </button>
              <p
                className={styles.skipText}
                onClick={() => {
                  saveSchedule();
                  navigate("/register/success");
                }}
              >
                דלג
              </p>
            </div>
          </div>
        </Modal>
      )} */}
    </div>
  );
};

export default Availability;
