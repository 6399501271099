import styles from "./service.module.css";

const Service = ({ id, name, price, isExactPrice, time, description }) => {
  return (
    <div className={styles.card}>
      <div className={styles.cardImg}>
        <h3>{name.slice(0, 1)}</h3>
      </div>

      <div className={styles.cardContent}>
        <h5 className={styles.name}>{name}</h5>
        <p className={styles.desc}>{description}</p>

        <div className={styles.row}>
          <div className={styles.block}>
            <img src="/assets/clock-2.svg" />
            <p>{time} דקות</p>
          </div>
          {price > 0 && (
            <div
              className={styles.block}
              style={{
                maxWidth: !isExactPrice ? "120px" : "60px",
                minWidth: !isExactPrice ? "100px" : "60px",
              }}
            >
              <p>
                {isExactPrice ? " " : "החל מ-"}₪{price}
              </p>
            </div>
          )}
        </div>
      </div>

    </div>
  );
};

export default Service;
