import { useState, useEffect } from "react";
import FinalPreviewCard from "../containers/finalPreviewCard";
import UpdateProfileCard from "../containers/updateProfileCard";
import { EditIconSvg } from "../svgs/editIconSvg";
import { EyeIconSvg } from "../svgs/eyeIconSvg";
import { useNavigate } from "react-router-dom";
import ProgressBar from "../components/ProgressBar";
import styles from "../styles/updateProfile.module.css";

const UpdateProfile = () => {
  const navigate = useNavigate();

  // REDIRECT IF NO TOKEN
  useEffect(() => {
    let token = localStorage.getItem("auth_token");
    if (!token) {
      navigate("/register");
    }
  }, []);

  const [formdata, setFormdata] = useState({
    name: "",
    description: "",
    businessName: "",
    businessImage: "",
    profileImage: "",
    phone: "",
    whatsApp: "",
    businessAddress: "",
    instagramId: "",
    facebook: "",
    tiktok: "",
    website: "",
    color: "",
    images: [],
    previewImages: [],
  });

  const [view, setView] = useState(0);

  const [isBelow992px, setIsBelow992px] = useState(window.innerWidth < 992);

  useEffect(() => {
    const handleResize = () => {
      setIsBelow992px(window.innerWidth < 992);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className={styles.container}>
      <ProgressBar />
      <div className={styles.wrapper}>
        {/* <div className={styles.toggleView}>
          <div className={styles.box} onClick={() => setView(0)}>
            <EditIconSvg fill={view === 0 ? "#DE1468" : "#000000"} />
          </div>
          <div className={styles.box} onClick={() => setView(1)}>
            <EyeIconSvg fill={view === 1 ? "#DE1468" : "#000000"} />
          </div>
        </div> */}
        <div className={styles.row}>
          <div
            className={styles.column1}
            style={{
              display: isBelow992px && view === 1 ? "none" : "flex",
              direction: "rtl",
            }}
          >
            {/* <h1>הוספת פרטי העסק</h1> */}
            <UpdateProfileCard formdata={formdata} setFormdata={setFormdata} />
          </div>

          {/* PREVIEW */}
          {/* <div className={styles.verticalLine} />
          <div
            className={styles.column2}
            style={{
              display: isBelow992px && view === 0 ? "none" : "flex",
            }}
          >
            <h1>תצוגה מקדימה של האתר</h1>
            <FinalPreviewCard formdata={formdata} />
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default UpdateProfile;
