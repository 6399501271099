import Switch from "react-switch";
import styles from "../styles/dayName.module.css";
import { toast } from "react-toastify";

const DayName = ({
  dayId,
  dayName,
  status,
  setData,
  timing,
  isOpen,
  setIsOpen,
}) => {
  // HANDLE SWITCH BUTTON CHANGE
  const handleChange = (e) => {
    setData((prev) =>
      prev.map((obj) => {
        if (obj._id === dayId) {
          if (status) {
            return { ...obj, status: false };
          } else {
            if (
              timing.every((x) => x.start_time && x.end_time) &&
              timing.every((x) => x.services.length > 0)
            ) {
              return { ...obj, status: true };
            } else {
              toast.error("נא לוודא שכל השדות תקינים ובחרתם לפחות שירות אחד");
              return { ...obj, status: false };
            }
          }
        }
        return obj;
      })
    );
  };

  return (
    <div className={styles.container} onClick={() => setIsOpen(!isOpen)}>
      <Switch
        onChange={(e) => handleChange(e)}
        checked={status}
        offColor="#EBEBEB"
        onColor="#DE1468"
        checkedIcon={false}
        uncheckedIcon={false}
        className={styles.switch}
      />
      <p>{dayName}</p>

      <img
        src={`/assets/arrow-${isOpen ? "up" : "down"}.svg`}
        alt="arrow"
        className={styles.arrow}
      />
    </div>
  );
};

export default DayName;
